import {
  blackImg,
  blueImg,
  highlightFirstVideo,
  highlightFourthVideo,
  highlightSecondVideo,
  highlightThirdVideo,
  highlightFifthVideo,
  highlightsixVideo,
  highlightsevenVideo,
  highlighteightVideo,
  highlightnineVideo,
  whiteImg,
  yellowImg,
} from "../utils";



export const hightlightsSlides = [
  {
    id: 1,
    textLists: [
      "E-Card",
    ],
    video: highlightFirstVideo,
    videoDuration: 10,
    
  },
  {
    id: 3,
    textLists: ["Transaction"],
    video: highlightSecondVideo,
    videoDuration: 6,
  },
  {
    id: 4,
    textLists: ["Marketcap"],
    video: highlightFourthVideo,
    videoDuration: 12,
  },
  {
    id: 5,
    textLists: [
      "Exchange",
    ],
    video: highlightThirdVideo,
    videoDuration: 2,
  },
  {
    id: 6,
    textLists: ["Marketplace"],
    video: highlightFifthVideo,
    videoDuration: 5,
  },
  {
    id: 7,
    textLists: ["NFT-game"],
    video: highlightsixVideo,
    videoDuration: 3,
  },
  {
    id: 8,
    textLists: ["Crowdfund"],
    video: highlightsevenVideo,
    videoDuration: 6,
  },
  {
    id: 9,
    textLists: ["Ruffle"],
    video: highlighteightVideo,
    videoDuration: 5,
  },
  {
    id: 10,
    textLists: ["Discussion"],
    video: highlightnineVideo,
    videoDuration: 2,
  },
];

export const models = [
  {
    id: 1,
    title: "iPhone 15 Pro in Natural Titanium",
    color: ["#8F8A81", "#ffe7b9", "#6f6c64"],
    img: yellowImg,
  },
  {
    id: 2,
    title: "iPhone 15 Pro in Blue Titanium",
    color: ["#53596E", "#6395ff", "#21242e"],
    img: blueImg,
  },
  {
    id: 3,
    title: "iPhone 15 Pro in White Titanium",
    color: ["#C9C8C2", "#ffffff", "#C9C8C2"],
    img: whiteImg,
  },
  {
    id: 4,
    title: "iPhone 15 Pro in Black Titanium",
    color: ["#454749", "#3b3b3b", "#181819"],
    img: blackImg,
  },
];

export const sizes = [
  { label: '6.1"', value: "small" },
  { label: '6.7"', value: "large" },
];

export const footerLinks = [
];