import { appleImg, bagImg, searchImg } from '../utils';
const Navbar = () => {
  return (
    <header className="w-full py-5 sm:px-10 px-5 flex justify-between items-center">
      <nav className="flex w-full screen-max-width">
        <img src={appleImg} alt="Apple" width={30} height={20} />

        <div className="flex flex-1 justify-center max-sm:hidden">
            <div className="px-5 text-sm cursor-pointer text-gray hover:text-white transition-all">
              <a href='https://leo-nft-game.netlify.app'>NFT-game</a>
            </div>
            <div className="px-5 text-sm cursor-pointer text-gray hover:text-white transition-all">
              <a href='https://leo-exchange.netlify.app'>Exchage</a>
            </div>
            <div className="px-5 text-sm cursor-pointer text-gray hover:text-white transition-all">
              <a href='https://leo-transaction.netlify.app'>Transaction</a>
            </div>
            <div className="px-5 text-sm cursor-pointer text-gray hover:text-white transition-all">
              <a href='https://leo-marketcap.netlify.app'>Marketcap</a>
            </div>
            <div className="px-5 text-sm cursor-pointer text-gray hover:text-white transition-all">
              <a href='https://nft-marketplace-platform-nine.vercel.app'>Marketplace</a>
            </div>
            <div className="px-5 text-sm cursor-pointer text-gray hover:text-white transition-all">
              <a href='https://leo-ecard.netlify.app/'>Ecard</a>
            </div>
            <div className="px-5 text-sm cursor-pointer text-gray hover:text-white transition-all">
              <a href='https://crowdfund-kappa-liard.vercel.app/'>Crowdfund</a>
            </div>
            <div className="px-5 text-sm cursor-pointer text-gray hover:text-white transition-all">
              <a href='https://ruffle-vert.vercel.app/'>Ruffle</a>
            </div>
            <div className="px-5 text-sm cursor-pointer text-gray hover:text-white transition-all">
              <a href='https://discussion-platform.vercel.app'>Discussion</a>
            </div>
        </div>

        <div className="flex items-baseline gap-7 max-sm:justify-end max-sm:flex-1">
          <div className="px-5 text-sm cursor-pointer text-gray hover:text-white transition-all">Log in</div>
        </div>
      </nav>
    </header>
  )
}

export default Navbar